import React, {useEffect} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"


const RandomArtist = ({data}) => {
  // let randomLink = data.googleSheet.fields.hyperlinkArtists.map(a=>a.link)[Math.floor(Math.random()*data.googleSheet.fields.hyperlinkArtists.length)];
  // console.log(randomLink)
  // window.location.href = randomLink;
  useEffect(() => {
    console.log("hi", data.googleSheet.fields.hyperlinkArtists);
    let randomLink = data.googleSheet.fields.hyperlinkArtists.map(a=>a.link)[Math.floor(Math.random()*data.googleSheet.fields.hyperlinkArtists.length)];
    console.log(randomLink)
    window.location.href = randomLink;
    
  });

  return (
    <Layout>


      <p>Redirecting to one of the artists currently on view.</p>
      <p>If this page doesn't change, you may go back to the <Link to="/hyperlink-gallery">Hyperlink Gallery</Link>.</p>
    </Layout>
  )
}



export default RandomArtist

export const query = graphql`
query {
  googleSheet {
    fields {
      hyperlinkArtists {
        link
        text
      }
    }
  }
}
`
